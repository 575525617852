<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="block text-center">
            <h3>Enter Free Agency Today!</h3>
            <p>Free Agents is the #1 service for getting you off the bench and into the game!</p>
            <div class="btn-wrapper">
              <router-link to="/find-free-agent" class="btn btn-default">Find Free Agents</router-link>
            </div>
          </div>
          <div class="block text-center">
            <h3>How Free Agents Works</h3>
            <p>After you complete the “Find an Agent” form, our service starts reaching out to everyone in our database via a text message to anyone that matches your needs. Those Free Agents will have the power to text you back and get in the game. Yes, we are the matchmakers of the recreational sporting space!</p>
            <div class="btn-wrapper">
              <router-link to="/find-free-agent" class="btn btn-default">Find Free Agents For Your Games Now</router-link>
            </div>
          </div>
          <div class="block text-center">
            <p>Becoming a free agent adds you to our extensive free agents database. Others looking to find an agent can find you based on where you play and what your skillset is.</p>
             <div class="btn-wrapper">
              <router-link to="/become-free-agent" class="btn btn-default">Enter Free Agency Now</router-link>
             </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
  .block:not(:last-child) {
    margin-bottom: 50px;
  }
</style>